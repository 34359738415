<template>
  <v-dialog v-model="dialog" width="400">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-if="signerData.assisted" color="primary" depressed v-bind="attrs" v-on="on" small>
        Firmar <v-icon class="ml-2" small>mdi-signature-freehand</v-icon>
      </v-btn>
      <v-btn v-else color="error" depressed small>
        Firmar <v-icon class="ml-2" small>mdi-signature-freehand</v-icon>
      </v-btn>
    </template>

    <v-form @submit.prevent="submit">
      <v-card>
        <v-card-title>Verifica tu identidad</v-card-title>

        <v-card-text>
          <p class="subtitle-1">
            {{ signerCompleteName }}
          </p>
          <v-text-field
            v-model="nif"
            label="NIF"
            hide-details="auto"
            :error-messages="errorMessage"
            autofocus
            @keyup="cleanTin"
          />
          <p class="mt-3 body-2">NOTA: Introduce tu NIF sin guiones ni espacios</p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn type="submit" color="primary">Verificar </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { cleanTin } from "@/utils/helpers";

export default {
  props: {
    signerData: { type: Object, require: true },
    trainerTin: { type: String, required: true },
  },
  data: () => ({
    dialog: null,
    nif: null,
    errorMessage: null,
  }),
  methods: {
    submit() {
      this.errorMessage = null;
      const errorMessage = this.checkNif(this.nif);

      if (errorMessage) {
        this.errorMessage = errorMessage;
      } else {
        this.dialog = false;
        const signerId = this.signerData.studentInfo ? this.signerData.studentInfo.id : 0;
        this.$emit("tinVerified", signerId);
        this.nif = null;
      }
    },
    checkNif(v) {
      if (!v) return "Campo obligatorio";

      if (v === cleanTin(this.signerData.tin) || v === cleanTin(this.trainerTin)) {
        return null;
      } else {
        return "El NIF no es correcto";
      }
    },
    cleanTin() {
      this.nif = cleanTin(this.nif);
    },
  },
  computed: {
    signerCompleteName() {
      const name = this.signerData?.studentInfo?.name || null;
      const surname = this.signerData?.studentInfo?.surname || null;
      return name || surname ? `${name} ${surname}` : "Formador";
    },
  },
};
</script>

<style scoped></style>
